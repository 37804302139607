<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div v-sticky class="vd_edhea vg_button_group" style="padding-top: 16px; background: white">
      <editHeader :isShow="isShow" :btn="btn" :strForm="podcForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('podcForm')" />
      <div v-if="isShow" class="vd_export vg_mt_16">
        <el-button type="primary" size="small" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
        <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintPart">打印成品采购变更单</el-button>
        <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportPart">下载成品采购变更单PDF</el-button>
      </div>
      <div class="vd_export2 vg_mr_8 vg_mt_16">
        <span style="margin-right: 20px; color: red; font-size: 20px">第{{ podcForm.podc_num }}次修改</span>
        <el-button type="text" @click="recordDialogVisible = true">变更记录</el-button>
        <el-dialog :visible.sync="recordDialogVisible" append-to-body title="变更记录" width="70%" @opened="getRecordData">
          <el-collapse accordion>
            <el-collapse-item :title="item.podc_record" :name="item.podc_record" v-for="(item, index) in recordList" :key="`item${index}`">
              <el-collapse>
                <el-collapse-item title="主内容">
                  <span
                    v-for="podc in item.podcHostRecordList"
                    :key="podc.podc_host_record"
                    v-html="podc.podc_host_record.replace('现改为', '<span style=\'color:red\'>现改为</span>') + '<br/>'"
                  >
                  </span>
                </el-collapse-item>
                <el-collapse-item title="部件明细">
                  <div v-for="(podcPart, podcPartIndex) in item.podcHostRecordPartList" :key="`podcPart${podcPartIndex}`">
                    <span
                      v-for="(subPodcPart, subPodcPartIndex) in podcPart"
                      :key="`subPodcPart${subPodcPartIndex}`"
                      v-html="subPodcPart.podc_host_record.replace('现改为', '<span style=\'color:red\'>现改为</span>') + '<br/>'"
                    ></span>
                    <br />
                  </div>
                </el-collapse-item>
                <el-collapse-item title="费用明细">
                  <div v-for="(podcFees, podcFeesIndex) in item.podcHostRecordFeesList" :key="`podcFees${podcFeesIndex}`">
                    <span
                      v-for="(subPodcFees, subPodcFeesIndex) in podcFees"
                      :key="`subPodcFees${subPodcFeesIndex}`"
                      v-html="subPodcFees.podc_host_record.replace('现改为', '<span style=\'color:red\'>现改为</span>') + '<br/>'"
                    >
                    </span>
                    <br />
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </el-dialog>
        <span style="margin-right: 2px">变更单据号:</span>
        <el-input size="mini" style="width: 150px" v-model="podcForm.podc_no" disabled show-word-limit> </el-input>
      </div>
    </div>
    <el-form ref="podcForm" :model="podcForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="变更类型:" prop="podc_type">
              <el-select
                ref="typeSelect"
                v-model="podcForm.podc_type"
                :disabled="false"
                filterable
                placeholder="请选择变更类型"
                size="small"
                @change="typeChange"
              >
                <el-option v-for="item in changeTypeList" :key="item.id" :disabled="item.id === 6" :label="item.label" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="原合同编号" prop="podr_no">
              <el-input v-model="podcForm.podr_no" :disabled="podcDisableForm.podr_no" maxlength="20" placeholder="请填写原合同编号" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="原供应商简称" prop="supp_abbr">
              <el-input v-model="podcForm.supp_abbr" :disabled="podcDisableForm.supp_abbr" maxlength="20" placeholder="请填写原供应商简称" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="采购经办人" prop="podr_stff_id">
              <el-select
                v-model="podcForm.podr_stff_id"
                :disabled="podcDisableForm.podr_stff_id"
                clearable
                filterable
                placeholder="请选择采购经办人"
                size="small"
              >
                <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="外销经办人">
              <el-input :value="podcForm.cust_stff_name" disabled maxlength="20" placeholder="请填写" show-word-limit />
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :md="8">
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="外销合同号:" prop="scon_no">
              <el-input v-model="podcForm.scon_no" :disabled="podcDisableForm.scon_no" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="新合同编号" prop="new_podr_no">
              <el-input v-model="podcForm.new_podr_no" :disabled="podcDisableForm.new_podr_no" maxlength="20" placeholder="请填写新合同编号" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item :rules="[{ required: [3, 4].includes(podcForm.podc_type), message: ' ' }]" label="新供应商简称" prop="new_supp_id">
              <el-select v-model="podcForm.new_supp_id" :disabled="podcDisableForm.new_supp_id" filterable placeholder="请选择供应商简称">
                <el-option v-for="item in suppList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="采购所属部门" prop="podr_dept_id">
              <el-select v-model="podcForm.podr_dept_id" :disabled="podcDisableForm.podr_dept_id" filterable placeholder="请选择采购所属部门" size="small">
                <el-option v-for="item in deptList" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="24">
            <el-form-item label="公司抬头" prop="cptt_id">
              <el-select v-model="podcForm.cptt_id" :disabled="podcForm.podc_type === 1" filterable placeholder="请选择公司抬头" size="small">
                <el-option v-for="item in cpttList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :md="8">
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="备注(内部流转)" prop="podc_remark">
              <el-input v-model="podcForm.podc_remark" :rows="5" maxlength="500" placeholder="请填写" show-word-limit type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" class="vg_mb_5">
            <el-form-item label="结算方式">
              <el-select v-model="podcForm.podc_payway" filterable placeholder="请选择">
                <el-option
                  v-for="item in [
                    { id: 1, label: '我司结算' },
                    { id: 2, label: '工厂结算' }
                  ]"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row>
        <el-col :md="24">
          <el-button v-show="podcForm.podc_type === 6" class="vg_mb_8" size="small" type="warning" @click="convertToInventory()">转为库存</el-button>
          <ProdDetail
            ref="ProdDetail"
            :isShow="isShow"
            :suppList="suppList"
            :podcForm="podcForm"
            :podc-disable-form="podc_part_list_disable_form"
            @SelectChnage="SelectChnage"
            @changeConent="() => {}"
          ></ProdDetail>
        </el-col>
        <el-col v-show="podcForm.podc_type === 6" :md="24" class="vg_mb_8">
          <el-button :disabled="isShow" class="vg_mb_8" type="danger" size="small" @click="delRow()">删除</el-button>
          <DynamicUTable
            ref="dynamicUTable"
            :tableData="subTableData"
            :columns="podcSubTableProperties"
            :need-pagination="false"
            :is-show="isShow"
            @selection-change="val => (subSelections = val)"
          >
          </DynamicUTable>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="24">
          <el-form-item label="变更内容(给工厂)" prop="modc_content">
            <el-input v-model="podcForm.podc_content" :rows="5" maxlength="5000" placeholder="请填写变更内容(给工厂)" show-word-limit type="textarea">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row>
        <el-tabs v-model="activeName" class="vd_eltab vd_buttom">
          <el-tab-pane :key="'first'" label="费用明细" name="first">
            <div class="vd_but vg_mb_5">
              <el-button v-if="podcForm.podc_type === 2" plain size="small" type="success" @click="feesAdd">新增</el-button>
            </div>
            <CostList :podcForm="podcForm" :podc_fees_list_disable_form="podc_fees_list_disable_form" @feesSelect="feesSelect"></CostList>
          </el-tab-pane>
          <el-tab-pane :key="'second'" label="条款明细" name="second">
            <CaluseList :isShow="isShow" :podcDisableForm="podcDisableForm" :podcForm="podcForm"></CaluseList>
          </el-tab-pane>
        </el-tabs>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="podcForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, getNoCatch, postNoCatch } from '@api/request';
import { podcAPI } from '@api/modules/podc';
import { deptAPI } from '@api/modules/department';
import { stffAPI } from '@/api/modules/staff';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import ProdDetail from './Component/ProdDetail.vue';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { suppAPI } from '@api/modules/supp';
import { deleteTableRow, getArrayIds } from '@assets/js/arrayUtils';
import { podcSubTableProperties } from '@/views/SalesManagement/PodcManage/podc';
import CostList from '@/views/SalesManagement/PodcManage/TabChild/Component/CostList.vue';
import CaluseList from '@/views/SalesManagement/PodcManage/TabChild/Component/ClauseList.vue';
import { cloneDeep } from 'lodash';
import { changeStaffForm, getCptt, getSupp } from '@api/public';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'podcEditMain',
  components: {
    DynamicUTable,
    CaluseList,
    CostList,
    SearchTable,
    editHeader,
    inputUser,
    ProdDetail
  },
  data() {
    return {
      podcSubTableProperties: podcSubTableProperties,
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      suppList: [],
      tableData: [],
      rules: {
        podc_type: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      otherForm: {},
      podcForm: {
        podc_no: null,
        podr_no: null,
        new_podr_no: null,
        podc_num: null,
        supp_abbr: null,
        new_supp_id: null,
        podc_type: null,
        podc_remark: null,
        podr_stff_id: null,
        podr_dept_id: null,
        cust_stff_id: null,
        cust_dept_id: null,
        mtrb_name: null,
        podc_item: null,
        podc_part_list: [],
        podc_fees_list: []
      },
      podcDisableForm: {},
      podc_part_list_disable_form: {},
      podc_fees_list_disable_form: {},
      stffList: [],
      deptList: [],
      purchaseTypeList: [
        { id: 1, label: '成品采购' },
        { id: 2, label: '委托加工' }
      ], //采购类型
      changeTypeList: [
        { id: 1, label: '取消订单' },
        { id: 2, label: '订单修改' },
        { id: 3, label: '转厂' },
        // { id: 4, label: '部分转厂' },
        { id: 5, label: '采购价修改' },
        { id: 6, label: '转为库存' }
      ], //变更类型
      isShow: true,
      otherDisabled: false,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      selectChangeList: [],
      activeName: 'first',
      recordDialogVisible: false,
      recordCollapseName: '',
      recordList: [],
      cpttList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      this.loadingFlag = true;
      await this.getPodcInfo();
      await this.getDept();
      await this.getStffUser();
      let [suppObj, suppList] = await getSupp('valueListAndDefault');
      this.suppList = suppList;
      this.podcSubTableProperties.find(({ prop }) => prop === 'supp_id').subItem.options = suppObj;
      await this.initDisableForm();
      this.cpttList = await getCptt();
    },
    initDisableForm() {
      for (let formKey in this.podcForm) {
        this.podcDisableForm[formKey] = true;
      }
      for (let subKey in this.podcForm.podc_part_list.length > 0 ? this.podcForm.podc_part_list[0] : {}) {
        this.podc_part_list_disable_form[subKey] = true;
      }
      for (let subKey in this.podcForm.podc_fees_list.length > 0 ? this.podcForm.podc_fees_list[0] : {}) {
        this.podc_fees_list_disable_form[subKey] = true;
      }
    },
    async changeTypeCommon(newVal) {
      await this.initDisableForm();
      setTimeout(() => {
        switch (newVal) {
          case 1:
            this.podcForm.podc_fees_list.forEach(item => {
              item.podc_fees_usd = null;
            });
            this.podcForm.podc_part_list.forEach(item => {
              item.podc_num = 0;
              item.fact_podc_total = 0;
            });
            for (let podcDisableFormKey in this.podcDisableForm) {
              this.podcDisableForm[podcDisableFormKey] = true;
            }
            for (let subKey in this.podc_part_list_disable_form) {
              this.podc_part_list_disable_form[subKey] = true;
            }
            break;
          case 2:
            this.podc_part_list_disable_form.podc_spec = false;
            this.podc_part_list_disable_form.prod_desc = false;
            this.podc_part_list_disable_form.podc_num = false;
            this.podc_part_list_disable_form.podc_qpb = false;
            this.podc_part_list_disable_form.podc_qpc = false;
            this.podc_part_list_disable_form.podc_box_unit = false;
            this.podc_part_list_disable_form.new_podr_part_pedate = false;
            this.podc_part_list_disable_form.new_supp_bid = false;
            this.podc_part_list_disable_form.new_prod_bar = false;
            this.podc_part_list_disable_form.new_prod_inbar = false;
            this.podc_part_list_disable_form.new_prod_outbar = false;
            this.podc_part_list_disable_form.prod_boxl = false;
            this.podc_part_list_disable_form.prod_boxw = false;
            this.podc_part_list_disable_form.prod_boxh = false;
            this.podc_part_list_disable_form.total_gross_weight = false;
            this.podc_part_list_disable_form.total_net_weight = false;
            this.podcDisableForm.clau_content = false;
            this.podcDisableForm.podc_address = false;
            this.podcDisableForm.cust_shipmark = false;
            this.podcDisableForm.podc_remark = false;
            break;
          case 3:
            this.podcDisableForm.new_supp_id = false;
            this.podc_part_list_disable_form.podc_spec = false;
            this.podc_part_list_disable_form.prod_desc = false;
            this.podc_part_list_disable_form.podc_num = false;
            this.podc_part_list_disable_form.podc_price = false;
            this.podc_part_list_disable_form.podc_qpb = false;
            this.podc_part_list_disable_form.podc_qpc = false;
            this.podc_part_list_disable_form.podc_box_unit = false;
            this.podc_part_list_disable_form.new_podr_part_pedate = false;
            this.podc_part_list_disable_form.new_supp_bid = false;
            this.podc_part_list_disable_form.new_prod_bar = false;
            this.podc_part_list_disable_form.new_prod_inbar = false;
            this.podc_part_list_disable_form.new_prod_outbar = false;
            break;
          case 5:
            this.podc_part_list_disable_form.podc_spec = false;
            this.podc_part_list_disable_form.prod_desc = false;
            this.podc_part_list_disable_form.podc_num = false;
            this.podc_part_list_disable_form.podc_qpb = false;
            this.podc_part_list_disable_form.podc_qpc = false;
            this.podc_part_list_disable_form.podc_box_unit = false;
            this.podc_part_list_disable_form.new_podr_part_pedate = false;
            this.podc_part_list_disable_form.new_supp_bid = false;
            this.podc_part_list_disable_form.new_prod_bar = false;
            this.podc_part_list_disable_form.new_prod_inbar = false;
            this.podc_part_list_disable_form.new_prod_outbar = false;
            this.podc_part_list_disable_form.prod_boxl = false;
            this.podc_part_list_disable_form.prod_boxw = false;
            this.podc_part_list_disable_form.prod_boxh = false;
            this.podc_part_list_disable_form.total_gross_weight = false;
            this.podc_part_list_disable_form.total_net_weight = false;
            this.podcDisableForm.clau_content = false;
            this.podcDisableForm.podc_address = false;
            this.podcDisableForm.cust_shipmark = false;
            this.podcDisableForm.podc_remark = false;
            this.podc_part_list_disable_form.podc_price = false;
            break;
          case 6:
            // this.podcForm.new_supp_id = null;
            break;
        }
        this.podcDisableForm = cloneDeep(this.podcDisableForm);
        this.podc_part_list_disable_form = cloneDeep(this.podc_part_list_disable_form);
        this.podc_fees_list_disable_form = cloneDeep(this.podc_fees_list_disable_form);
      }, 200);
    },
    typeChange(val) {
      this.$confirm('变更类型将会重置数据!', '是否变更类型?', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(async () => {
        this.loadingFlag = true;
        this.getPodcInfo(val);
        await this.$refs.typeSelect.blur();
      });
    },
    getRecordData() {
      getNoCatch(podcAPI.getPodcRecord, { podc_id: this.podcForm.podc_id }).then(({ data }) => {
        this.recordList = [data.fromList];
      });
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message.info('已撤销编辑!');
        })
        .catch(() => {});
    },
    //变更类型
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.podcForm.validate((valid, object) => {
            console.log(valid, object);
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              this.$message.error('请把必填内容补充完整!');
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 保存
    saveInfo() {
      let podcForm = JSON.parse(JSON.stringify(this.podcForm));
      podcForm.podcInventoryList = this.subTableData.concat(Array.from(this.subTableMap.values()));
      podcForm.podc_part_list.forEach(item => {
        if (item.new_podr_part_pedate) item.new_podr_part_pedate /= 1000;
        if (item.podr_part_pedate) item.podr_part_pedate /= 1000;
      });
      postNoCatch(podcAPI.editPodc, podcForm).then(({ data }) => {
        this.$message.success('保存成功!');
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
      });
    },
    // pdf
    clickPrintPart() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.podcForm.podc_id;
          get(podcAPI.exportPodcPdfById, { podc_id: str })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExportPart() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.podcForm.podc_id;
          get(podcAPI.exportPodcPdfById, { podc_id: str })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, '成品采购变更单.pdf');
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 选择子表select
    SelectChnage(val) {
      this.selectChangeList = JSON.parse(JSON.stringify(val));
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取部门
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取表单信息
    getPodcInfo(val) {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      getNoCatch(podcAPI.getPodcById, { podc_id: props.form_id }).then(({ data }) => {
        this.podcForm = data.form;
        this.btn = data.btn;
        this.subTableData = data.form.podcInventoryList;
        changeStaffForm(this.stffForm, this.podcForm);
        if (this.podcForm.create_time === this.podcForm.update_time) {
          this.isShow = false;
          this.$emit('isShow', this.isShow);
        }
        this.calcForm();
        if (val) {
          setTimeout(async () => {
            this.podcForm.podc_type = val;
            await this.changeTypeCommon(val);
          });
        } else {
          setTimeout(async () => {
            await this.changeTypeCommon(this.podcForm.podc_type);
          });
        }
        this.loadingFlag = false;
      });
    },
    // 计算内容
    calcForm() {
      for (let i = 0; i < this.podcForm.podc_part_list.length; i++) {
        this.podcForm.podc_part_list[i].podr_part_pedate = !this.podcForm.podc_part_list[i].podr_part_pedate
          ? null
          : this.podcForm.podc_part_list[i].podr_part_pedate * 1000;
        this.podcForm.podc_part_list[i].new_podr_part_pedate = !this.podcForm.podc_part_list[i].new_podr_part_pedate
          ? null
          : this.podcForm.podc_part_list[i].new_podr_part_pedate * 1000;
        if (this.podcForm.podc_part_list[i].prod_price) {
          this.podcForm.podc_part_list[i].prod_price = this.helper.haveFour(this.podcForm.podc_part_list[i].prod_price);
        }
        if (this.podcForm.podc_part_list[i].podc_price) {
          this.podcForm.podc_part_list[i].podc_price = this.helper.haveFour(this.podcForm.podc_part_list[i].podc_price);
        }
        if (this.podcForm.podc_part_list[i].podc_total) {
          this.podcForm.podc_part_list[i].podc_total = this.helper.haveFour(this.podcForm.podc_part_list[i].podc_total);
        }
      }
    },
    getSupp() {
      getNoCatch(suppAPI.getSuppsV1).then(({ data }) => {
        this.suppList = data;
      });
    },
    // 新增费用明细
    feesAdd() {
      let item = {
        podc_fees_name: '',
        podc_fees_usd: '',
        podc_fees_remark: '',
        key: this.feesKey,
        isNew: true
      };
      this.podcForm.podc_fees_list.push(item);
    },
    // 费用选择
    feesSelect(val) {
      this.feesSelectList = JSON.parse(JSON.stringify(val));
    },
    convertToInventory() {
      if (this.selectChangeList.length === 0) return this.$message.warning('请选择一条数据!');
      let ids = getArrayIds(this.subTableData, 'podc_part_id');
      this.selectChangeList.forEach(item => {
        if (ids.includes(item.podc_mtrb_id)) return;
        let originalObject = cloneDeep(this.$refs.dynamicUTable.originalObject);
        originalObject.prod_no = item.prod_no;
        originalObject.prod_cust_no = item.prod_cust_no;
        originalObject.prod_name = item.prod_partno;
        originalObject.prod_spec = item.prod_spec;
        originalObject.prod_poststfix = item.prod_poststfix;
        originalObject.podc_part_id = item.podc_part_id;
        this.subTableData.push(originalObject);
      });
      this.$refs.ProdDetail.$refs.multiTable.clearSelection();
    },
    delRow() {
      if (this.subSelections.length === 0) return this.$message.warning('请选择一条数据!');
      let { tableData, map } = deleteTableRow('podc_inventory_id', this.subTableData, this.subTableMap, this.subSelections);
      this.subTableData = tableData;
      this.subTableMap = map;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
.vd_word {
  word-wrap: break-word;
  word-break: normal;
}
.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
</style>
