<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" class="addAndEdit" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab3 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab2 />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PodcEditMain from '@/views/SalesManagement/PodcManage/TabChild/PodcEditMain';
import PodcEditAbout from '@/views/SalesManagement/PodcManage/TabChild/PodcEditAbout';
import PodcEditAppr from '@/views/SalesManagement/PodcManage/TabChild/PodcEditAppr';

export default {
  name: 'PodcEdit',
  components: {
    childTab1: PodcEditMain,
    childTab2: PodcEditAbout,
    childTab3: PodcEditAppr
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style lang="scss" scoped>
.addAndEdit > ::v-deep .el-tabs__content {
  height: calc(100vh - 179px);
  overflow-y: auto;
  padding-top: 0;
}
</style>
