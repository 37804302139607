<template>
  <div class="podcProdList">
    <el-row class="vg_mb_5">
      <el-col :md="24">
        <div class="vd_flex">
          <div style="width: 120px; textalign: right; paddingright: 10px">
            <div>条款内容</div>
            <el-link :disabled="podcDisableForm.clau_content" class="vg_pointer" type="primary" @click="dialogVisible = true">选择</el-link>
          </div>
          <el-input
            v-model="podcForm.clau_content"
            :disabled="podcDisableForm.clau_content"
            :rows="3"
            placeholder="请填写条款内容"
            show-word-limit
            type="textarea"
          ></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="24">
        <el-form-item label="送货地址" :prop="'podc_address'">
          <el-input
            :disabled="podcDisableForm.podc_address"
            v-model="podcForm.podc_address"
            type="textarea"
            maxlength="100"
            :rows="3"
            show-word-limit
            placeholder="请填写送货地址"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_5">
      <el-col :md="24">
        <el-form-item label="唛头" :prop="'cust_shipmark'">
          <el-input
            v-model="podcForm.cust_shipmark"
            :disabled="podcDisableForm.cust_shipmark"
            :rows="3"
            placeholder="请填写唛头"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <el-form-item label="备注" :prop="'podc_remark'">
          <el-input
            :disabled="podcDisableForm.podc_remark"
            v-model="podcForm.podc_remark"
            type="textarea"
            maxlength="255"
            :rows="3"
            show-word-limit
            placeholder="请填写备注"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-dialog title="文本信息" :visible.sync="dialogVisible" width="70%">
      <ClauseImport @importClauChange="importClauChange"></ClauseImport>
    </el-dialog>
  </div>
</template>

<script>
import ClauseImport from './ClauseImport.vue';

export default {
  name: 'PartList',
  props: {
    podcForm: {
      type: Object,
      required: true
    },
    podcDisableForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ClauseImport
  },
  data() {
    return {
      selectionsList: [],
      prodUnitList: [],
      value1: '是',
      value2: '否',
      flagShow: true,
      dialogVisible: false
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    importClauChange(row) {
      this.podcForm.clau_content = row.clau_content;
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
.vd_flex {
  display: flex;
}
.vd_wid {
  width: 50px;
}
</style>
